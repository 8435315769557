<template>
  <div id="app">
    <h1>{{ repos.length ? "We've Got Some Issues" : 'Loading...'}}</h1>
    <p class="tip">*new = created in last 7 days; active = updated in last 30 days</p>
    <div id="container">
      <repo v-for="repo in repos"
        :key="repo.name"
        :name="repo.name"
        :issues="repo.issues">
      </repo>
    </div>
  </div>
</template>

<script>
import Repo from './Repo.vue'
import repos from './repos.js'

const API_BASE = 'https://api.github.com/'

function get (resource) {
  return fetch(`${API_BASE}${resource}`).then(res => res.json())
}

export default {
  name: 'app',
  components: { Repo },
  data () {
    return {
      repos: []
    }
  },
  mounted () {
    Promise.all(repos.map(r => {
      return get(`repos/${r}/issues?per_page=100`)
    })).then(issues => {
      this.repos = issues.map((list, i) => ({
        name: repos[i],
        issues: list
      }))
    })
  }
}
</script>

<style lang="stylus">
body
  margin 0

#app
  font-family -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"
  -webkit-font-smoothing antialiased
  -moz-osx-font-smoothing grayscale
  color #2c3e50
  padding 0 30px

a
  text-decoration none
  color #2c3e50

#container
  display flex
  flex-wrap wrap
  justify-content left

.tip
  color #999
  font-size .9em
  margin-bottom 2em
</style>
