<template>
  <div class="repo">
    <h3>
      <a :href="`https://github.com/${name}/issues`" target="_blank">
        {{ name }} ({{ totalOpenCount }})
      </a>
    </h3>
    <div class="stats">
      <a class="new" :href="newLink" target="_blank">new {{ newCount }}</a>
      <a class="active" :href="activeLink" target="_blank">active {{ activeCount }}</a>
      <br>
      <a class="pull-request" :href="PRLink" target="_blank">pull requests {{ PRCount }}</a>
      <a v-for="label in labels"
        v-if="getLabelCount(label) > 0"
        :class="formatLabelClass(label)"
        :href="getLabelURL(label)"
        target="_blank">
        {{ label }} {{ getLabelCount(label) }}
      </a>
      <a class="no-replies" :href="noRepliesLink" target="_blank">no replies {{ noRepliesCount }}</a>
    </div>
  </div>
</template>

<script>
const WEEK = 1000 * 60 * 60 * 24 * 7
const MONTH = 1000 * 60 * 60 * 24 * 30

export default {
  props: {
    name: String,
    issues: Array
  },
  data () {
    return {
      labels: ['bug', 'improvement', 'feature request']
    }
  },
  computed: {
    newCount () {
      return this.issues.filter(i => {
        return Date.now() - new Date(i.created_at) < WEEK
      }).length
    },
    newLink () {
      const date = new Date(Date.now() - WEEK).toISOString().slice(0, 10)
      return `https://github.com/${this.name}/issues?q=is%3Aissue%20is%3Aopen%20created%3A>%3D${date}`
    },
    activeCount () {
      return this.issues.filter(i => {
        return !i.pull_request && (Date.now() - new Date(i.updated_at) < MONTH)
      }).length
    },
    activeLink () {
      const date = new Date(Date.now() - MONTH).toISOString().slice(0, 10)
      return `https://github.com/${this.name}/issues?q=is%3Aissue%20is%3Aopen%20updated%3A>%3D${date}`
    },
    PRCount () {
      return this.issues.filter(i => i.pull_request).length
    },
    PRLink () {
      return `https://github.com/${this.name}/pulls`
    },
    totalOpenCount () {
      return this.issues.filter(i => !i.pull_request).length
    },
    noRepliesCount () {
      return this.issues.filter(i => !i.comments && !i.pull_request).length
    },
    noRepliesLink () {
      return `https://github.com/${this.name}/issues?q=is%3Aissue%20is%3Aopen%20comments%3A0`
    }
  },
  methods: {
    getLabelCount (label) {
      return this.issues.filter(i => i.labels.some(l => l.name === label)).length
    },
    getLabelURL (label) {
      return `https://github.com/${this.name}/issues?q=is%3Aissue%20is%3Aopen%20label%3A"${encodeURIComponent(label)}"`
    },
    formatLabelClass: label => label.replace(/\s/g, '-')
  }
}
</script>

<style lang="stylus">
.repo
  display block
  width 350px
  border 1px solid #e5e5e5
  border-radius 3px
  background-color #fff
  padding 20px 30px
  margin 0 30px 30px 0
  h3
    font-size 1em
    margin -20px -30px 20px
    padding 15px 30px
    border-bottom 1px solid #e5e5e5
    background-color #f8f8f8

.stats a
  display inline-block
  white-space nowrap
  margin 0 8px 8px 0
  color #fff
  font-size .85em
  padding .2em .5em .3em
  border-radius 2px
  font-weight 600
  &.new
    background-color #6cc644
    font-size 1em
  &.active
    background-color #FF9900
    font-size 1em
  &.no-replies
    background-color #999
  &.pull-request
    background-color #8E7CC3
  &.bug
    background-color #fc2929
  &.improvement
    color #333
    background-color #c7def8
  &.feature-request
    color #333
    background-color #fbca04
</style>
